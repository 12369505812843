const Footer = () => {
    return <div className="footer">
        <p>Risk Warning: Derivative products are leveraged products and can result in losses that exceed initial deposits. Please ensure you fully understand the risks and take care to manage your exposure and seek independent advice if necessary. It's important for you to consider relevant legal documents. (Product Disclosure and Financial Services Guide) before you decide whether or not to acquire any of our products.</p>
        <p>TF Global Markets (Aust) Limited is the holder of Australian Financial Services License (AFSL) number 424700. Registered address: Level 18, 357 Collins Street, Melbourne, VIC, Australia 3000. ABN: 69158361561.</p>
        <p>This AFSL authorises us to provide our services to people or businesses that are located in Australia.</p>
        <p>The information on this site is not directed to residents of the United States, Canada and Japan and is not intended for distribution to, or use by, any person in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.    </p >
        <div className="c">
            <p> &copy;{` ${new Date().getFullYear()} `}This website is owned and operated by ThinkMarkets Group</p>
        </div>
    </div>
}

export default Footer
