import cn from 'classnames'
import { ReactComponent as Image } from "..//images/loader.svg"
import styles from "./style.module.scss"

export enum LoaderMode { CoverParent, StandAlone, FullScreen }
interface LoaderProps {
    mode?: LoaderMode
}

const Loader = ({ mode = LoaderMode.FullScreen }: LoaderProps) => {

    const classes = cn(
        styles.loader,
        {
            [styles.cover]: mode === LoaderMode.CoverParent,
            [styles.full]: mode === LoaderMode.FullScreen,
        }
    )

    return (
        <div className={classes}>
            <Image />
        </div>
    )
}

export default Loader