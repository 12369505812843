import React, { useEffect, useState } from 'react'
import Splash from './Splash'
import Header from './Header'
import Info from './Info'
import Done from './Done'
import Footer from './Footer'
import { Params, Platform } from './types'
import queryString from 'query-string'

const App = () => {
  const [view, setView] = useState<'info' | 'done'>('info')
  const [params, setParams] = useState<Params>()

  useEffect(() => {
    //https://tfbodev-cportal.lo:8443/cportal/prop/fundedtrader?email=aaa_fundedtrader76@mailinator.com&firstName=Roman&lastName=Nikolov&brokerName=TestFirmOne&currency=USD&country=US&platform=ThinkTrader
    const query = queryString.parse(window.location.search)

    //     const urlForReplacement = getParamsString
    //     ? `${window.location.pathname}?${getParamsString}`
    //     : `${window.location.pathname}${window.location.hash}`
    // window.history.replaceState({}, '', urlForReplacement)
    console.log('parameters:', query)
    if (process.env.NODE_ENV === 'production')
      setParams({
        email: query.email as string,
        firstName: query.firstName as string,
        lastName: query.lastName as string,
        brokerName: query.brokerName as string,
        currency: query.currency as string,
        platform: query.platform as Platform,
        // platform: query.platform as Platform || "MT4",
        country: query.country as string
      })
    else
      setParams({
        email: query.email as string || "aaa_fundedtrader76@mailinator.com",
        firstName: query.firstName as string || "Roman",
        lastName: query.lastName as string || "Nikolov",
        brokerName: query.brokerName as string || "Test broker",
        currency: query.currency as string || "USD",
        platform: query.platform as Platform || "ThinkTrader",
        // platform: query.platform as Platform || "MT4",
        country: query.country as string || "US"
      })
  }, [])

  return (
    <div className='app'>
      <Header />
      <div className='main'>
        {params && <div className='content'>
          <Splash platform={params.platform} />
          {view === 'info' && <Info params={params} afterSubmit={() => setView('done')} />}
          {view === 'done' && <Done />}
        </div>}
        <Footer />
      </div>
    </div>
  )
}

export default App
