import { useCallback, useState } from "react"
import Button from "./Button"
import Loader from "./Loader"
import { Params } from "./types"

const Info = ({ params, afterSubmit }: { params: Params, afterSubmit: () => void }) => {
    const [submiting, setSubmiting] = useState(false)
    const [error, setError] = useState(false)

    const line = useCallback((caption: string, value: string | number) => {
        return <div className="line">
            <span>{caption}</span>
            <span>{value}</span>
        </div>
    }, [])

    const create = useCallback(async () => {
        setError(false)
        setSubmiting(true)
        const url = `${process.env.REACT_APP_API_URL}/cportal/prop/demo/accounts/create-complete`
        try {
            await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify(params),
            })

        }
        catch { setError(true) }
        finally { setSubmiting(false) }
        // afterSubmit()
    }, [params])

    return <div className="card info">
        {submiting && <Loader />}
        <div>Please save a copy of the following information for future reference.</div>
        <div className="table">
            {line('First name', params.firstName)}
            {line('Last name', params.lastName)}
            {line('Email', params.email)}
            {line('Country', params.country)}
            {line('Currency', params.currency)}
            {line('Platform', params.platform)}
            {line('Introducing broker', params.brokerName)}
        </div>
        <div className="action">
            <Button text="Create account" onClick={create} />
        </div>
        {error && <div className="error">Error submiting your request</div>}
    </div>
}

export default Info

