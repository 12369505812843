/* eslint-disable jsx-a11y/alt-text */
import cn from 'classnames'
import { useMemo } from "react"
import mt4 from './images/mt4-laptop.png'
import mt5 from './images/mt5-laptop.png'
import tt from './images/ttweb-laptop.png'
import { Platform } from "./types"

const Splash = ({ platform }: { platform: Platform }) => {

    const image = useMemo(() => {
        switch (platform) {
            case 'MT4': return mt4
            case 'MT5': return mt5
            default: return tt
        }
    }, [platform])

    return <div className="splash">
        <div className="title">Start your Virtual Trading Account Today!</div>
        <img src={image} className={cn({ "tt": image === tt })} />
    </div>
}

export default Splash