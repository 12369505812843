import done from './images/done.svg'

const Done = () => {
    return <div className="card done">
        <img src={done} alt="" />
        <h3>The account was created successfully.</h3>
        A confirmation email has been sent providing additional information.
    </div>
}

export default Done

